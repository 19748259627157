<template>
 <b-card title="العناصر">
    <b-table responsive :items="Object.values(orderProducts)"  :fields="tableColumns"> 
        <template #cell(id)="data">
            {{ data.index + 1 }}
        </template>

    </b-table>   
 </b-card>
</template>


<script>
import { BCard } from 'bootstrap-vue'
import { BTable } from 'bootstrap-vue'
import { number } from 'echarts/lib/export';
export default {
    components: {
        BCard,
        BTable
    },
    props : {
        orderProducts: {
            type: [Object, Array],
            required: true,
        }
    },
    data() {
        return {
            tableColumns: [
                { key: 'id', label:'الرقم', sortable: true },
                { key: 'name', label: 'اﻹسم', sortable: true },
                { key: 'amount', label: 'العدد', sortable: true},
        ],
        }
    },
}
</script>