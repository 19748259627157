<template>
  <b-card-code title="Navigations">
    <swiper
      class="swiper-navigations"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(data,index) in swiperData"
        :key="index"
      >
        <b-img
          :src="data.img"
          fluid
        />
      </swiper-slide>

      <!-- Add Arrows -->
      <div
        slot="button-next"
        class="swiper-button-next"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
    </swiper>

    <template #code>
      {{ codeNavigation }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import { codeNavigation } from './codeSwiper/code'


export default {
  components: {
    Swiper,
    SwiperSlide,
    BCardCode,
    BImg,
  },
  props: {
    orderImages : {
        type: [Array, Object],
        required: true,
    },
  },
  data() {
    return {
      codeNavigation,

      /* eslint-disable global-require */
      // swiperData: [
      //   { img: 'https://imgs.dev-almanea.com/media/catalog/product/5/0/5043cf82-7418-401e-a687-be7363824333.jpg?width=640&quality=100&format=webp' },
      //   { img: require('@/assets/images/banner/banner-4.jpg') },
      //   { img: require('@/assets/images/banner/banner-14.jpg') },
      //   { img: require('@/assets/images/banner/banner-3.jpg') },
      //   { img: require('@/assets/images/banner/banner-2.jpg') },
      // ],

      swiperData: this.orderImages,
      /* eslint-disable global-require */

      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  methods: {
    
  },
  mounted() {
    console.log('images');
    console.log(this.orderImages);
  },
}
</script>
