<template>
  <b-card title="رحلة الطلب">

     <b-table responsive :items="orderDataStatus"  :fields="tableColumns"> 
        <template #cell(time)="data">
             <!-- {{  new Date(data.value * 1000).toDateString() }} -->
             {{  new Date(data.value * 1000) | moment('YY-MM-DD HH:mm:ss') }}
             
         </template> 

         
 
     </b-table>   
  </b-card>


 </template>
 
 
 <script>

 import { number } from 'echarts/lib/export';
 import { BTable , BCard, BCardText } from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

 export default {
     components: {
         BCard,
         BTable,
         BCardText,
         AppTimeline,
         AppTimelineItem,
     },
     props : {
         orderDataStatus: {
             type: [Array, Object],
             required: true,
         }
     },
     data() {
         return {
             tableColumns: [
                //  { key: 'id', sortable: true },
                 { key: 'log', label: 'التعليق', sortable: true },
                 { key: 'time', label: 'التاريخ', sortable: true },
         ],
         }
     },
 }
 </script>