<template>
  <b-card-code title="الصور">
    <swiper
      class="swiper-multiple"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(data,index) in swiperData"
        :key="index"
      >
        <b-img
          :src="data.img"
          fluid
        />
      </swiper-slide>

      <div
        slot="pagination"
        class="swiper-pagination"
      />
    </swiper>

    <template #code>
      {{ codeMultipleSlides }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import { codeMultipleSlides } from './codeSwiper/code'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BCardCode,
    BImg,
  },
  props: {
    orderImages : {
        type: [Array, Object],
        required: true,
    },
  },
  data() {
    return {
      codeMultipleSlides,

      /* eslint-disable global-require */
      // swiperData: [
      //   { img: "https://firebasestorage.googleapis.com/v0/b/op-plus.appspot.com/o/images%2F1731338948152.jpg?alt=media&token=bd25217f-88ff-415c-a55b-8f3a9c9c8e93" },
      //   // { img: require('@/assets/images/banner/banner-32.jpg') },
      //   // { img: require('@/assets/images/banner/banner-33.jpg') },
      //   // { img: require('@/assets/images/banner/banner-34.jpg') },
      //   // { img: require('@/assets/images/banner/banner-35.jpg') },
      // ],

      

      swiperData: this.orderImages,
      /* eslint-disable global-require */

      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },

      
    }
  },
  mounted() {
    // console.log('images');
    // console.log(this.orderImages);
  },
}
</script>
