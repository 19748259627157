<template>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >

    <b-row>
      <b-col
          cols="12"
          lg="12"
        >
        <order-data  :orderData="orderData"  :orderDataItemsNumber="orderDataItemsNumber" :orderStatus="orderStatus"/>
      </b-col>
    </b-row>

    <b-row>
      <b-col
          cols="12"
          lg="6"
        >
          <customer-info :userData="userData" />
        </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <provider :provider="provider" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6">
        <order-status :orderDataStatus="orderDataStatus" />
      </b-col>

      <b-col cols="12" lg="6">
        <order-products :orderProducts="orderProducts" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="12">
        <div v-if="!isDataLoaded">Loading...</div>
        <!-- <swiper-navigation v-else :orderImages="orderImages"/> -->
        <swiper-multiple-slides v-else :orderImages="orderImages"/>
        
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="12">
        <div v-if="!isDataLoaded">Loading...</div>

        <!-- <order-maps v-else  :fromLocation="fromLocation" :toLocation="toLocation" :waypoints_ob="waypoints_ob"/> -->

        <order-maps-marker v-else  :fromLocation="fromLocation" :toLocation="toLocation" :waypoints_ob="waypoints_ob"/>
        
      </b-col>
    </b-row>
  

  <!-- <b-card>
    <tab  :machineData="machineData" :orderData="orderData"/>
  </b-card> -->

</b-overlay>

</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge, BOverlay
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DataService  from './data/services'
import Tab from './profile/tab'

import customerInfo from './components/customerInfo.vue'
import provider from './components/provider.vue'
import orderStatus from './components/orderStatus.vue'
import orderProducts from './components/orderProducts.vue'
import orderData from './components/orderData.vue'
import orderMaps from './components/orderMaps.vue'
import orderMapsMarker from './components/orderMapsMarker.vue'
import SwiperNavigation from './components/SwiperNavigation.vue'
import SwiperMultipleSlides from './components/SwiperMultipleSlides.vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BOverlay,
    ToastificationContent,
    Tab,
    customerInfo,
    provider,
    orderStatus,
    orderProducts,
    orderData,
    orderMaps,
    orderMapsMarker,
    SwiperNavigation,
    SwiperMultipleSlides,
  },

  data() {
    return {
      userData: {},
      machineData: [],
      provider: {},
      orderData: [],
      orderDataItemsNumber: 0,
      orderDataStatus: [],
      orderImages: [],
      isLoading: true,
      orderProducts: {},
      fromLocation:{},
      toLocation:{},
      waypoints_ob:[],
      isDataLoaded: false,
      // status: [
      //   {
      //     0: 'في انتظار عروض الاسعار', 1: 'مؤكدة', 2: 'تم الوصول لموقع الطلب', 3: 'مكتملة', 4: 'ملغية', 5: 'تم الوصول لموقع الطلب', 6: 'في الطريق لموقع التسليم',
      //   },
      //   {
      //     0: 'light-primary', 1: 'light-info', 2: 'light-danger', 3: 'light-success', 4: 'light-danger', 5: 'light-info', 6: 'light-info',
      //   }
      // ],
      orderStatus:0,
    }
  },
  methods: {
      getData(){
            this.isLoading = true;
            var arr = [];
            DataService.get(this.$route.params.id)
            .then(response => {
              // console.log("images");
              // console.log(response.data.images);

                  this.isDataLoaded = true; // Set flag to true once data is loaded

                  this.isLoading = false
                  this.userData    = response.data.customer
                  this.provider = response.data.provider
                  this.orderData   = response.data
                  this.orderDataItemsNumber = response.data.items.length;
                  this.orderStatus = response.data.status
                  this.orderProducts = response.data.items
                  console.log('status ='+ this.orderStatus)
                  this.fromLocation = response.data.fromLocation
                  this.toLocation = response.data.toLocation
                  this.waypoints_ob = [
                    { lat: response.data.fromLocation.latitude, lng: response.data.fromLocation.longitude },
                    { lat: response.data.toLocation.latitude, lng: response.data.toLocation.longitude }
                  ];

                  console.log(this.waypoints_ob[0].lat);
                  
                  // for (let index = 0; index < response.data.images.length; index++) {
                  for (const [key, value] of Object.entries(response.data.images)) {
                    var obj = {};
                    // obj['key'] = `${key}`;
                    obj['img'] = `${value}`;
                    arr.push(obj)
                    
                  }

                  // console.log(arr)
                  // this.orderImages = response.data.images;
                  this.orderImages = arr;
                  
                  this.$toast({
                        component: ToastificationContent,
                        props: {
                              title: 'successfully',
                              icon: 'BellIcon',
                              text: response.data.responseDescription,
                              variant: 'success',
                        },
                  })
            }).catch((error) => {
              console.error("Error fetching data:", error);
              this.isDataLoaded = false; // Handle error
            });
      },
      getOrderLogs() {
        this.isLoading = true;
        DataService.getOrderLogs(this.$route.params.id)
            .then(response => {
                this.orderDataStatus = response.data
            })
      },
      changeStatus(id){
            this.isLoading = true;
            DataService.changeStatus(id)
            .then(response => {
            this.isLoading = false
            if(this.userData.status == 1){
                  this.userData.status = 0
            }else{
                  this.userData.status = 1
            }
            this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'successfully',
                  icon: 'BellIcon',
                  text: response.data.responseDescription,
                  variant: 'success',
                  },
            })
            
            
            })
      },
      
  },
  mounted() {
    this.getData()
    this.getOrderLogs()
  },
  
}
</script>

<style scoped>
/* Force LTR direction for this container */
.ltr-container {
  direction: ltr;
  text-align: left;
}
</style>
