<template>
    <div>
      <p>الخريطة</p>

      <l-map :zoom="zoom" :center="center">
        <l-tile-layer
          :url="osmUrl"
          :attribution="attribution"/>
        <l-routing-machine :waypoints="waypoints"/>
      </l-map>

      <!-- <div>
        waypoints
        {{ waypoints_ob }}
      </div> -->

    </div>
  </template>
  
  <script>
  import {
     LMap, LTileLayer, LMarker, LCircle, LPolygon,

   } from "vue2-leaflet";
  import "leaflet/dist/leaflet.css";
  import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
  import L from "leaflet";
  import "leaflet-routing-machine";
  import "leaflet-control-geocoder"; // Import Geocoder library
  import "leaflet-control-geocoder/dist/Control.Geocoder.css"; // Import Geocoder 
  
  import LRoutingMachine from './components/LRoutingMachine'
import { props } from "vue-prism-component";
  
  const attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  const osmUrl = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png'

//   import { codeBasic } from './code'

  
  export default {
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LRoutingMachine,
    },
    props : {
        fromLocation: {
        type: [Object, Array],
        required: true,
      },
      toLocation : {
        type: [Object, Array],
        required: true,
      },
      waypoints_ob: {
        type: [Array, Object],
        required: true,
      },
    },
    data() {
        return {
            zoom: 6,
            center: { lat: 38.7436056, lng: -2.2304153 },
            osmUrl,
            attribution,
            waypoints: this.waypoints_ob,
            // codeBasic,
        };
      
    },
    methods: {
        
  
    },
  };
  </script>
  
 
 

<style lang="scss">
 /* @import "/node_modules/leaflet/dist/leaflet.css";
  @import "/node_modules/leaflet-routing-machine/dist/leaflet-routing-machine.css";  */

.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>
  